import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
// import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import environment from "./enviroment";
import Index from "./pages/Router/Index";
import UserView from "./pages/user/userview/UserView";
import UserList from "./pages/user/userList";

// import OrderDetails from "./pages/order/details/detail";
import "react-toastify/dist/ReactToastify.css";

//IMPORT USER  ROUTES COMPONENT
import User from "./parentPages/User";

import UserEdit from "./pages/user/edit/edit";
import UserCreate from "./pages/user/create/create";

// IMPORT PRODUCT ROUTES COMPONENT
import Product from "./parentPages/Product";
import ProductList from "./pages/product/list/list";
import ProductEdit from "./pages/product/edit/edit";
import Items from "./pages/product/items/items";
// import ProductDetail from './pages/product/detail/detail'
import ProductCreate from "./pages/product/create/create";

// IMPORT ORDER ROUTES COMPONENT

import Order from "./parentPages/order";
import OrderList from "./pages/order/list/list";
import OrderDetails from "./pages/order/details/detail";

//IMPORTING PAYMENT ROUTES COMPONENT
import Payment from "./parentPages/payment";
import PaymentCreate from "./pages/payment/create/create";
import PaymentList from "./pages/payment/list/list";
import PaymentEdit from "./pages/payment/edit/edit";

//IMPORTING INVOICE ROUTES COMPOENT
import Invoice from "./parentPages/invoice";
import InvoiceList from "./pages/invoice/list/list";
import InvoiceDetail from "./pages/invoice/detail/detail";

//IMPORTING LOGIN COMPONENT
import Login from "./Component/login/Login";

//IMPORTING VISIT COMPONENT

import Visit from "./parentPages/Visit";
import VisitCreate from "./pages/visit/create/create";
import VisitEdit from "./pages/visit/edit/edit";
import VisitList from "./pages/visit/list/list";
import VisitView from "./pages/visit/ViewVisit/view";
import LiveLocation from "./pages/visit/liveLocation/view"

import PrivateRoute from "./parentPages/privateRoute";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCurrentUser } from "./Component/redux/astroSlice";

//IMPORTING COMMITMENT
import CommitmentParentPage from "./parentPages/commitment"
import  CreateCommitment from "./pages/commitment/create/create"
import EditCommitment from "./pages/commitment/edit/edit"


//Page Not Found
import NotFoundPage from "./pages/pageNotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />, // Default component for /
  },
  {
    path: "/dashboard",
    element: <Index />,

    children: [
      {
        path: "user",

        element: (
          <PrivateRoute>
            {" "}
            <User />
          </PrivateRoute>
        ), // Parent component for user routes, it should contain <Outlet />
        children: [
          {
            index: true,
            element: <UserList />, // Default component for /user
          },
          {
            path: "create",
            element: <UserCreate />,
          },
          {
            path: "edit/:id",
            element: <UserEdit />,
          },
        ],
      },

      {
        path: "product",
        element: (
          <PrivateRoute>
            {" "}
            <Product />
          </PrivateRoute>
        ), // Parent component for user routes, it should contain <Outlet />
        children: [
          {
            index: true,
            element: <ProductList />, // Default component for /user
          },
          {
            path: "create",
            element: <ProductCreate />,
          },
          {
            path: "edit/:id",
            element: <ProductEdit />,
          },
          {
            path: "items",
            element: <Items />,
          },
        ],
      },
      {
        path: "order",
        element: <Product />, // Parent component for user routes, it should contain <Outlet />
        children: [
          {
            index: true,
            element: <OrderList />, // Default component for /user
          },
          {
            path: "detail/:id",
            element: <OrderDetails />,
          },
        ],
      },

      {
        path: "payment",
        element: (
          <PrivateRoute>
            {" "}
            <Payment />
          </PrivateRoute>
        ),
          // Parent component for user routes, it should contain <Outlet />
        children: [
          {
            index: true,
            element: <PaymentList />, // Default component for /user
          },
          {
            path: "create/:id",
            element: <PaymentCreate />,
          },
          {
            path: "edit/:id",
            element: <PaymentEdit />,
          },
        ],
      },

      {
        path: "invoice",
        element: (
          <PrivateRoute>
            {" "}
            <Invoice />
          </PrivateRoute>
        ),
             // Parent component for user routes, it should contain <Outlet />
        children: [
          {
            index: true,
            element: <InvoiceList />, // Default component for /user
          },
          {
            path: "detail/:id",
            element: <InvoiceDetail />,
          },
        ],
      },


      {
        path: "Commitment",
        element: (
          <PrivateRoute>
            {" "}
            <CommitmentParentPage />
          </PrivateRoute>
        ),
             // Parent component for user routes, it should contain <Outlet />
        children: [
          {
            path: "create/:id",
            element: <CreateCommitment />, // Default component for /user
          },
          {
            path: "edit/:id",
            element: <EditCommitment />,
          },
        ],
      },






      {
        path: "visit", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <Visit />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <VisitList />, // Default component for /user
          },
          {
            path: "create",
            element: <VisitCreate />,
          },
          {
            path: "edit/:id",
            element: <VisitEdit />,
          },

          {
            path: "view",
            element: <VisitView />,
          },
          {
            path: "liveLocation",
            element: <LiveLocation />,
          },
        ],
      },

      {
        path: "*",
        element:<NotFoundPage/>,
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  async function fetchMasterData() {
    const CurrentUser = JSON.parse(localStorage.getItem("user"));
    dispatch(addCurrentUser(CurrentUser));
  }

  useEffect(() => {
    fetchMasterData();
  },[]);





  useEffect(() => {
    // Function to handle tab visibility changes
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
        // Handle the tab becoming inactive
      } else {
        console.log('Tab is active');
        // Handle the tab becoming active
        // Your custom function
      }
    };

    // Add visibilitychange event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);



  return <RouterProvider router={router} />;
}

export default App;
