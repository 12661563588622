import React, { useEffect, useRef, useState } from "react";
import "./createStyle.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import axios from "axios";

import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

// import Payment from "../../../services/payment.service";
import User from "../../../services/user.service";
import InVoice from "../../../services/invoice.service";
import Visit from "../../../services/visit.service";
import CONSTANT from "../../../constant/Option";
import { useParams } from "react-router-dom";
import OPTIONS from "../../../constant/Option";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import getLocation from "../../../utils/Location.Tracker";
import Helper from "../../../utils/helper";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Create = () => {
  //HANDLING THE SELECT OPTION OF ROLE
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = React.useState({
    employeeName: "",
    customerName: "",
    typeOfVisit: "NEW",
    inVoiceId: "",
    email: "",
    latitude: "",
    longitude: "",
  });

  // Access query parameters
  const id = searchParams.get("id");
  const typeOfVisit = searchParams.get("typeOfVisit")?.toUpperCase();

  const [loading, setLoading] = React.useState(false);

  const Roles = ["GRAM", "KG", "LITER", "MILI"];

  //**************************** */

  //HANDLING THE IMAGE UPLOAD
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [location, setLocation] = useState({ lat: null, lng: null });
  const [error, setError] = useState(null);
  // const { id } = useParams();

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  const handleInputChange = (name, value) => {
    // let name=e.target.name;
    // let value=e.target.value;
    console.log("change input called", name, value);
    setFormData({ ...formData, [name]: value });

    console.log("your formdata", formData);
  };

  function CleanData() {
    const fields = Object.keys(formData);
    console.log("your fields", fields);
    let newObject = {};
    fields.map((key) => {
      newObject[key] = "";
    });
    setImagePreview(null);
    setFormData(newObject);
  }

  const handleSubmit = async () => {
    try {
      const newformData = new FormData();
      newformData.append("lat", location.latitude);
      newformData.append("lng", location.longitude);
      Object.keys(formData).map((key) => {
        if (formData[key]) {
          newformData.append(key, formData[key]);
        }
      });
      if (Helper.isFormValid2(formData, [
   
        { fieldName: "customerName", rules: { isRequired: formData.typeOfVisit==OPTIONS.visitType.NEW? true:false } },
        { fieldName: "phoneNumber", rules: { isRequired: formData.typeOfVisit==OPTIONS.visitType.NEW? true:false ,isPhoneNumber:formData.typeOfVisit==OPTIONS.visitType.NEW?true:false} },
       
       
      ])) {
        let data = await Visit.Create(newformData);
        CleanData();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  async function fetchData() {
    console.log(
      "****called Data Fetch*****",
      typeOfVisit,
      OPTIONS.visitType.COLLECTION,
      typeOfVisit == OPTIONS.visitType.COLLECTION
    );

    if (typeOfVisit == OPTIONS.visitType.COLLECTION) {
      console.log("****inside called Data Fetch*****");
      const data = await InVoice.getById(id);
      const newdata = data.data;
      console.log("your data", newdata);
      setFormData({
        ...formData,
        customerName:
          newdata.customerInfo.firstName + " " + newdata.customerInfo.lastName,
        email: newdata.customerInfo.email,
        inVoiceId: newdata._id,
        customerId: newdata.customerInfo._id,
        typeOfVisit: typeOfVisit,
      });
    }

    if (typeOfVisit == OPTIONS.visitType.ORDER) {
      console.log("****inside called Data Fetch*****");
      const data = await User.getById(id);
      const newdata = data.data;
      console.log("your data", newdata);
      setFormData({
        ...formData,
        customerName: newdata.firstName + " " + newdata.lastName,
        email: newdata.email,
        customerId: newdata._id,
        typeOfVisit: typeOfVisit,
      });
    }
  }

  async function FetchUserLocation() {
    const location = await getLocation();
    console.log("**********Got the Location*******", location);
    setLocation(location);
  }

  useEffect(() => {
    fetchData();
    FetchUserLocation();
  }, []);

  return (
  <Box sx={{height:"100%",overflowY:"auto"}}>  <div>
  <div className="row w-100 m-0 p-0">
    <div className="col-12 m-0 p-0">
      <div className="row w-100 ">
        <div className="col-12">
          {" "}
          <Typography
            variant="h"
            fontSize={25}
            p={1}
            style={{ fontWeight: "bold" }}
          >
            Visit#{6010}
          </Typography>
          <Chip color={"error"} label={250} />
        </div>
        <div className="col-12">
          <Typography variant="caption" fontSize={15} p={1}>
            15 jul 2024 8:27 am
          </Typography>
        </div>
      </div>
    </div>

    <div className="col-12 my-3 ">
      <Stack marginLeft={2} direction="row" spacing={2}>
        <Link
          component="button"
          variant="body"
          onClick={() => {
            console.info("I'm a button.");
          }}
          sx={{ color: "black", textDecoration: "none" }}
        >
          DashBoard
        </Link>
        <Link
          component="button"
          variant="body"
          sx={{ color: "black", textDecoration: "none" }}
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Visit
        </Link>

        <Link
          component="button"
          variant="body"
          sx={{ color: "black", textDecoration: "none" }}
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Create
        </Link>
      </Stack>
    </div>

    <div
      className="col-md-4 col-12 d-flex justify-content-center"
      id="uploadSection"
    >
      <div className="row " style={{ width: "98%" }}>
        <div
          className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
          style={{
            maxHeight: "300px",
            minHeight: "300px",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div
            className="rounded-circle border d-flex justify-content-center align-items-center"
            style={{
              minWidth: "150px",
              minHeight: "150px",
              background: "darkgrey",
            }}
            onClick={handleDivClick}
          >
            {imagePreview || formData.imageUrl ? (
              <img
                src={imagePreview ? imagePreview : formData.imageUrl}
                className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                style={{
                  minWidth: "130px",
                  maxWidth: "130px",
                  minHeight: "130px",
                  maxHeight: "130px",
                  background: "white",
                }}
              />
            ) : (
              <div
                className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                style={{
                  minWidth: "130px",
                  minHeight: "130px",
                  background: "white",
                }}
              >
                <CameraAltIcon></CameraAltIcon>
                <span>Upload Photo</span>
              </div>
            )}
          </div>
          <span className="my-3"> PNG jPEG GIF</span>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>

    <div
      className="col-md-7 col-11  my-2 py-1 mx-2 mx-auto d-flex justify-content-center rounded "
      style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
    >
      <div
        className="row  d-flex justify-content-center my-sm-5 my-2 "
        style={{ width: "95%" }}
      >
        <div className="col-12 col-md-6  my-md-3 my-1 ">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Visit Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.typeOfVisit}
              label="Visit Type"
              name="typeOfVisit"
              onChange={(e) =>
                handleInputChange(e.target.name, e.target.value)
              }
            >
              {CONSTANT.visitType.getAllVisitAsArray().map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>

        {formData.typeOfVisit == OPTIONS.visitType.COLLECTION ? (
          <div className="col-12 col-md-6 my-md-3 my-1 p">
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Invoice ID"
              value={formData.inVoiceId}
              name="inVoiceId"
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.value);
              }}
              variant="outlined"
            />
          </div>
        ) : null}

        <div className="col-12 col-md-6  my-md-3 my-1 ">
          <TextField
            step="1"
            oninput="validity.valid||(value='');"
            id="outlined-basic"
            sx={{ width: "100%" }}
            label="Owner Name"
            variant="outlined"
            inputProps={{ min: 1, step: 1 }} // Enforce positive integers
            value={formData.customerName}
            name="customerName"
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.value);
            }}
          />
        </div>

        {[OPTIONS.visitType.COLLECTION, OPTIONS.visitType.ORDER].includes(
          formData.typeOfVisit
        ) ? (
          <div className="col-12 col-md-6  my-md-3 my-1">
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Customer Email"
              variant="outlined"
              value={formData.email}
              name="email"
              color="error"
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.value);
              }}
            />
          </div>
        ) : null}

        {![OPTIONS.visitType.COLLECTION, OPTIONS.visitType.ORDER].includes(
          formData.typeOfVisit
        ) ? (
          <div className="col-12 col-md-6  my-md-3 my-1">
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="phoneNumber"
              variant="outlined"
              value={formData.phoneNumber}
              name="phoneNumber"
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.value);
              }}
            />
          </div>
        ) : null}

        <div className="col-12 text-center my-md-3 my-1">
          {/* <Button variant="contained" sx={{color:"white",background:"black"}} onClick={handleSubmit}>Submit</Button> */}
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            sx={{ color: "white", background: "black" }}
            startIcon={<AddIcon sx={{ fontSize: 1 }} />}
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </div>
  </div>
</div></Box>
  );
};

export default Create;
