import * as React from "react";
import styles from "./cartDrawer.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Switch, FormControlLabel } from "@mui/material";

import User from "../../services/user.service";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { closeCart, openCart } from "../redux/astroSlice";
import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import cartItem from "../../services/cartItem.service";

import CloseIcon from "@mui/icons-material/Close";
import { SetMealOutlined } from "@mui/icons-material";
import CartService from "../../services/cart.service";
import OPTIONS from "../../constant/Option";
import Order from "../../services/order.service";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

export default function CartDrawer() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const open1 = useSelector((state) => state.Cart);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to store selected user ID
  const CartAction = useSelector((state) => state.Cart);
  const [cart, setCart] = useState({});
  const currentUser = useSelector((state) => state.currentUser);
  const [isDiscount, setIsDiscount] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Replace this URL with your API endpoint to fetch users
      const response = await User.getAllUser();
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("****your selected id**", selectedUserId);
  }, [selectedUserId]);

  // Fetch users when the autocomplete is opened
  const handleOpen = () => {
    setOpen(true);
    fetchUsers(); // Fetch users only if options list is empty
  };

  const FetchCart = async () => {
    const response = await CartService.getCart();
    console.log("Fetch The Cart", response.data);
    setCart(response.data);
  };


  useEffect(()=>{
    console.log("fetch change gthe Set Cart",cart);
  })

   async function ToggetDiscount(checked){
    const response=await CartService.updateDiscount({isDiscountActive:checked});
  }

  

  useEffect(() => {
    if (CartAction) {
      FetchCart();
    }
  }, [CartAction]);

  async function CheckOut() {
    try {
      if (
        OPTIONS.usersRoles.onlyCustomerLevelAccess().includes(currentUser.role)
      ) {
        setSelectedUserId((e) => currentUser._id);
      }
      console.log("***your User Id****", selectedUserId);
      if (selectedUserId) {
        await Order.Create({ customerId: selectedUserId });

        dispatch(closeCart());
      } else {
        toast.error("Select The Customer First");
      }
    } catch (error) {}
  }

  const DrawerList = (
    <Box sx={{ width: "100vw", maxWidth: "450px" }} role="presentation">
      <div className={`container`}>
        <div className="row my-2">
          <div className="col-10 ">
            <h1>Shoppig Cart</h1>
          </div>
          <div className="col-2">
            <CloseIcon
              onClick={(e) => {
                dispatch(closeCart());
              }}
            />
          </div>
        </div>

        <div className={`${styles.shoppingCart}`}>
          {/* Header */}
          <div className=" row d-flex justify-content-between align-items-center mb-3 my-2">
            <div className="col-12">
              <Autocomplete
                open={open}
                onOpen={handleOpen}
                onClose={() => setOpen(false)}
                getOptionLabel={(option) =>
                  option.name.toUpperCase() + "     " + option.email
                } // Display user's name in the dropdown
                options={options}
                loading={loading}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedUserId(newValue._id); // Store the selected user's ID
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search User"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>

          {/* Free Shipping Notice */}

         {
            OPTIONS.usersRoles.onlyEmployeeAccess().includes(currentUser.role)? <div
            className="alert alert-success d-flex justify-content-between align-items-center p-2"
            role="alert"
          >
            <FormControlLabel
              control={
                <Switch
            checked={isDiscount}
            onChange={(event) => {
              const checked = event.target.checked;
              setIsDiscount(checked);
              ToggetDiscount(checked); // Pass the checked state as a parameter
            }}
            color="primary"
          />
              }
              label="Apply Discount"
            />
          </div>:null
         }

          {cart?.products?.map((item) => {
            return (
              <EachCartItem
                title={item.products.title}
                price={item.products.price}
                quantity={item.quantity}
                imageUrl={item.products.imageUrl}
                unit={item.products.unit}
                measureUnit={item.products.measureUnit}
                _id={item._id}
                salePrice={item.discountedPrice}
                isDiscount={isDiscount}
              />
            );
          })}

          {/* Cart Summary */}
          <div className="cart-summary pt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <i className="bi bi-pencil-fill me-2"></i>
                <a href="#">Note</a>
              </div>
              <div className="d-flex align-items-center">
                <i className="bi bi-ticket me-2"></i>
                <a href="#">Coupon</a>
              </div>
            </div>

            {/* Shipping and Subtotal */}
            <div className="d-flex justify-content-between">
              <span className="text-muted">Shipping:</span>
              <span className="fw-bold">
                {" "}
                {cart.cart?.shipping > 0 ? cart.cart?.shipping : "FREE"}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="text-muted">grandTotal:</span>
              <span className="fw-bold">Rs. {cart.cart?.grandTotal}</span>
            </div>
            {/* Checkout Button */}
            <div className="d-grid mt-4 my-2">
              {/* <button className={`${styles.btnPink} fw-bold`}>CHECK OUT</button> */}
              <Button
                className={`${styles.btnPink} fw-bold`}
                variant="contained"
                onClick={CheckOut}
              >
                Check out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open1}
        anchor="right"
        onClose={(e) => dispatch(closeCart())}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}

const EachCartItem = ({
  price,
  quantity,
  title,
  imageUrl,
  unit,
  measureUnit,
  _id,
  salePrice,
  isDiscount,
}) => {
  const [itemquantity, setItemQuantity] = useState(Number(quantity));
  const [temQuantity, setTempQuantity] = useState(Number(quantity));
  const [discountedPrice, setDiscountedPrice] = useState(Number(salePrice));
  const [tempDiscountedPrice, setTempDiscountedPrice] = useState(Number(salePrice));
  const dispatch = useDispatch();

  const handleIncrementDiscountPrice = async () => {
    try {
      const newdiscountedPrice = Number(discountedPrice) + 1;
   const response= await cartItem.updateDiscountPrice(_id,{discountedPrice:newdiscountedPrice} ); // Call backend to update quantity
   if(response.success){
    setDiscountedPrice(newdiscountedPrice);
    setTempDiscountedPrice(newdiscountedPrice);
  }
 
    } catch (error) {
      setDiscountedPrice(tempDiscountedPrice)
    }
  };

  // Handle decrement of quantity
  const handleDecrementDiscountPrice = async () => {
    try {
      const newdiscountedPrice = discountedPrice - 1;
     const response= await cartItem.updateDiscountPrice(_id,{discountedPrice:newdiscountedPrice} );
      if(response.success){
        setDiscountedPrice(newdiscountedPrice);
        setTempDiscountedPrice(newdiscountedPrice);
      }
    } catch (error) {
      setDiscountedPrice(tempDiscountedPrice)
    }
  };

  const handleIncrement = async () => {
    const newQuantity = Number(itemquantity) + 1;
    setItemQuantity(newQuantity);
    const response=   await cartItem.updateQuantity(_id, { quantity: newQuantity }); // Call backend to update quantity
    
        if(response.success){
          setItemQuantity(newQuantity);
          setTempQuantity(newQuantity)
        }else{
          setItemQuantity(temQuantity)
        }
  };

  // Handle decrement of quantity
  const handleDecrement = async () => {
    try {
      if (itemquantity > 1) {
        const newQuantity = itemquantity - 1;
       const response=  await cartItem.updateQuantity(_id, { quantity: newQuantity });
        if(response.success){
          setItemQuantity(newQuantity);
          setTempQuantity(newQuantity)
        }else{
          setItemQuantity(temQuantity)
        }
      }
    } catch (error) {}
  };

  const handleRemove = async () => {
    try {
      const response = await cartItem.remove(_id);
      dispatch(closeCart());
    } catch (error) {}
  };

  return (
    <>
      <div className="row cart-item py-3 border-bottom">
        <div className="col-3">
          <img
            src={imageUrl || OPTIONS.defaultImage.product}
            alt="Lal Mirch Bharua"
            className="img-fluid rounded"
          />
        </div>
        <div className="col-9 d-flex flex-column justify-content-between">
          <h5 className="">{title}</h5>
          <div className="d-flex justify-content-between">
            <span className="fw-bold ">Rs.{price}</span>
            <span className="me-3 fw-300">
              {unit}: {measureUnit}
            </span>
          </div>

          {isDiscount ? (
            <div
              className="input-group input-group-sm"
              style={{ maxWidth: "100%" }}
            >
              <div
                className="row w-100 p-0 m-0 my-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="col-3 p-0 m-0">
                  <span>Discount</span>
                </div>
                <div
                  className="col-8"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={handleDecrementDiscountPrice}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    className="form-control text-ce:nter"
                    value={discountedPrice}
                    style={{ minWidth: "80px", textAlign: "center" }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDiscountedPrice(value === "" ? "" : Number(value)); // Handle empty input separately
                    }}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={handleIncrementDiscountPrice}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className="input-group input-group-sm"
            style={{ maxWidth: "100%" }}
          >
            <div
              className="row w-100 p-0 m-0 my-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="col-3 p-0 m-0">
                <span>Quantity</span>
              </div>
              <div
                className="col-8"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleDecrement}
                >
                  -
                </button>
                <input
                  type="number"
                  className="form-control text-center"
                  style={{ minWidth: "80px", textAlign: "center" }}
                  value={itemquantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    setItemQuantity(value === "" ? "" : Number(value)); // Handle empty input separately
                  }}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleIncrement}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        <a
          href="#"
          className="text-danger  d-inline w-auto"
          onClick={(e) => handleRemove()}
        >
          Remove
        </a>
      </div>
    </>
  );
};

