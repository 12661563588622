import React from "react";
import { Box, Typography, Button } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
 const navigate=useNavigate()

 function handleBack(){
    navigate(-1)
 }


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        padding: "16px",
      }}
    >
      <SentimentDissatisfiedIcon
        sx={{
          fontSize: "100px",
          color: "#9e9e9e",
          marginBottom: "16px",
        }}
      />
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "50px", md: "80px" },
          fontWeight: "bold",
          color: "#424242",
          marginBottom: "8px",
        }}
      >
        404
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "16px", md: "20px" },
          color: "#757575",
          marginBottom: "24px",
        }}
      >
        OOPS! PAGE NOT BE FOUND
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "14px", md: "16px" },
          color: "#9e9e9e",
          marginBottom: "32px",
          maxWidth: "500px",
        }}
      >
        Sorry but the page you are looking for does not exist, has been
        removed, name changed, or is temporarily unavailable.
      </Typography>
      <Button
        variant="contained"
        color="success"
        sx={{
          textTransform: "none",
        
          color: "#fff",
          "&:hover": { backgroundColor: "#115293" },
        }}
       onClick={handleBack}
      >
        Back to homepage
      </Button>
    </Box>
  );
};

export default NotFoundPage;
