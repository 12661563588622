// import { toast } from "react-toastify";
// import axiosInstance from "../utils/requestInstance";
// import environment from "../enviroment";
// const API_URL = environment.endPoint;
// const CartService = {
//   async getCart() {
//     try {
//       const response = await axiosInstance.get(`/cart`);
//       let data = response.data.result;
//       return data;
//     } catch (error) {
//       console.log(error);
//       toast.error(error.message||"Cannot Complete This Action Now")
//     }
//   },

//   async addtoCart(payload) {
//     try {
//       const response = await axiosInstance.post(`/cart/addToCart`, payload);
//       const data=response.data.result
//       toast.success(data.message)
//       return data.data;
//     } catch (error) {
//       console.log(error);
//       toast.error(error.message||"Can not Add to Cart")
//     }
//   },
  
//   async getById(payload) {
//     try {
//       const response = await axiosInstance.get(`/invoice/getById/${payload}`);
//       return response.data.result;
//     } catch (error) {
//       console.log(error);
//     }
//   },

//   async updateById(payload, id) {
//     try {
//       const response = await axiosInstance.put(
//         `/invoice/update/${id}`,
//         payload
//       );

//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   },


//   async delete(payload, id) {
//     try {
//       const response = await axiosInstance.delete(
//         `/invoice/delete/${id}`,
//       );

//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   },




// };

// export default CartService;


import apiRequest from "../Component/customHool/axiosRequest";

const CartService = {
  async getCart() {
    return apiRequest('GET', '/cart', {}, {});
  },

  async addtoCart(payload) {
    return apiRequest('POST', '/cart/addToCart', payload, {});
  },

  async updateDiscount(payload, id) {
    return apiRequest('PUT', `/cart/updateDiscount`, payload, {});
  },



  async getById(payload) {
    return apiRequest('GET', `/invoice/getById/${payload}`, {}, {});
  },

  async updateById(payload, id) {
    return apiRequest('PUT', `/invoice/update/${id}`, payload, {});
  },

 

  async delete(id) {
    return apiRequest('DELETE', `/invoice/delete/${id}`, {}, {});
  },
};

export default CartService;
