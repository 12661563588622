import React, { useEffect, useRef, useState } from "react";
import "./createStyle.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import axios from "axios";

import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";

import { Edit } from "@mui/icons-material";


import Payment from "../../../services/payment.service";
import CONSTANT from "../../../constant/Option";
import { useParams } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};





const Create = () => {
  //HANDLING THE SELECT OPTION OF ROLE
  const [formData, setFormData] = React.useState({
    employeeName:"",
    customerName:"",
    typeOfPayment:"",
    amountRecived:"",
    inVoiceId:""
  });

  const[loading,setLoading]=useState(false);
  
  const Roles = ["GRAM", "KG", "LITER", "MILI"];

 

  //**************************** */

  //HANDLING THE IMAGE UPLOAD
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const{ id}=useParams();

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({...formData,image:file})
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  const handleInputChange=(name,value)=>{
    // let name=e.target.name;
    // let value=e.target.value;
    console.log("change input called",name,value)
    setFormData({...formData,[name]:value})
  }

  // useEffect(()=>{
  //   console.log("your form data is changed",formData)
  // },[formData])

  const handleSubmit=()=>{

    try {
      const newformData=new FormData();
    Object.keys(formData).map((key)=>{
     newformData.append(key,formData[key])
    })
    // delete newformData.customerName
    // delete newformData.employeeName
    setLoading(true);
    Payment.updateById(newformData,formData._id)
    setLoading(false);
      
    } catch (error) {
      setLoading(false);
    }

  }



  async function fetchData(){

    const data=await Payment.getById(id);
   const newdata=data.data;
    console.log("You Edit Data",data)
    setFormData({
      employeeName:newdata.employeeInfo.firstName+" "+ newdata.employeeInfo.lastName,
    customerName:newdata.customerInfo.firstName+" "+ newdata.customerInfo.lastName,
    _id:"",
    typeOfPayment:newdata.typeOfPayment,
    amountRecived:newdata.amountRecived,
    inVoiceId:newdata.inVoiceId,
    _id:newdata._id,
    imageUrl:newdata.imageUrl
      
    })

  }


  useEffect(()=>{
    fetchData();
},[])


  return (
    <Box sx={{height:"100%",overflowY:"auto"}}><div>
    <div className="row w-100 m-0 p-0">
      <div className="col-12 m-0 p-0">
      <div className="row w-100 ">
              <div className="col-12">
                {" "}
                <Typography
                  variant="h"
                  fontSize={25}
                  p={1}
                  style={{ fontWeight: "bold" }}
                >
                  Invoice#{6010}
                </Typography>
                <Chip color={"error"} label={250} />
              </div>
              <div className="col-12">
                <Typography variant="caption" fontSize={15} p={1}>
                  15 jul 2024 8:27 am
                </Typography>
              </div>
            </div>
      </div>

      <div className="col-12 my-3 ">
        <Stack marginLeft={2} direction="row" spacing={2}>
          <Link
            component="button"
            variant="body"
            onClick={() => {
              console.info("I'm a button.");
            }}
            sx={{ color: "black", textDecoration: "none" }}
          >
            DashBoard
          </Link>
          <Link
            component="button"
            variant="body"
            sx={{ color: "black", textDecoration: "none" }}
            onClick={() => {
              console.info("I'm a button.");
            }}
          >
            payment
          </Link>

          <Link
            component="button"
            variant="body"
            sx={{ color: "black", textDecoration: "none" }}
            onClick={() => {
              console.info("I'm a button.");
            }}
          >
           Create
          </Link>
        </Stack>
      </div>

      <div
        className="col-md-4 col-12 d-flex justify-content-center"
        id="uploadSection"
      >
        <div className="row " style={{ width: "98%" }}>
          <div
            className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
            style={{
              maxHeight: "300px",
              minHeight: "300px",
              boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          >
            <div
              className="rounded-circle border d-flex justify-content-center align-items-center"
              style={{ minWidth: "150px", minHeight: "150px",background:"darkgrey" }}
              onClick={handleDivClick}
            >
              {imagePreview||formData.imageUrl ? (
                <img
                  src={imagePreview?imagePreview:formData.imageUrl}
                  className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                  style={{
                    minWidth: "130px",
                    maxWidth: "130px",
                    minHeight: "130px",
                    maxHeight: "130px",
                    background:'white'
                  }}
                />
              ) : (
                <div
                  className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                  style={{
                    minWidth: "130px",
                    minHeight: "130px",
                    background: "white",
                  }}
                >
                  <CameraAltIcon></CameraAltIcon>
                  <span>Upload Photo</span>
                </div>
              )}
            </div>
            <span className="my-3"> PNG jPEG GIF</span>
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>

      <div
        className="col-md-7 col-11  my-2 py-1 mx-2 mx-auto d-flex justify-content-center rounded "
        style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div
          className="row  d-flex justify-content-center my-sm-5 my-2 "
          style={{ width: "95%" }}
        >
          <div className="col-12 col-md-6 my-md-3 my-1 p">
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Invoice ID"
              value={formData.inVoiceId}
              name='inVoiceId'
              // onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
              variant="outlined"
            />
          </div>
          <div className="col-12 col-md-6  my-md-3 my-1">
            <TextField
              id="outlined-basic"
             
              sx={{ width: "100%" }}
              label="Customer Name"
              variant="outlined"
              value={formData.customerName}
              name='customerName'
              // onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
            />
          </div>
          {/* <div className="col-12 col-md-6  my-md-3 my-1">
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Price"
              variant="outlined"
              value={formData.price}
              name='price'
              onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
            />
          </div>
          <div className="col-12 col-md-6 my-md-3 my-1">
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Sale Price"
              variant="outlined"
              value={formData.salePrice}
              name='salePrice'
              onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
            />
          </div> */}
          <div className="col-12 col-md-6  my-md-3 my-1 ">
            <TextField
            step="1" oninput="validity.valid||(value='');"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Amount"
              variant="outlined" 
              type="number"  // Set type to number for numerical input
              inputProps={{ min: 1, step: 1 }} // Enforce positive integers 
              helperText="Enter positive Amount Only"
               value={formData.amountRecived}
              name='amountRecived'
              onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
            />
          </div>
          <div className="col-12 col-md-6  my-md-3 my-1 ">
            <FormControl  sx={{ width: "100%" }}>
      <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={formData.typeOfPayment}
        label="Payment Type"
        name="typeOfPayment"
        onChange={e=>handleInputChange(e.target.name,e.target.value)}
      >
          {
             CONSTANT.defaultPaymentType.getAllDdefaultPaymentTypeasArray().map((item)=>{
                  return  <MenuItem value={item}>{item}</MenuItem>
              })
          }
       
      </Select>
    </FormControl>
          </div>
          {/* <div className="col-12 col-md-12 my-md-3 my-1 ">
            <TextField
              type="text"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Description"
              variant="outlined" 
              value={formData.description}
              name="description"
              onChange={e=>handleInputChange(e.target.name,e.target.value)}
            />
          </div> */}
          {/* <div className="col-12 col-md-6 my-md-3 my-1 ">
            <TextField
              type="text"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Confirm Pass"
              variant="outlined"
            />
          </div> */}
          <div className="col-12 text-center my-md-3 my-1">
            {/* <Button variant="contained" sx={{color:"white",background:"black"}} onClick={handleSubmit}>Submit</Button> */}
            <LoadingButton
                          loading={loading}
                          onClick={handleSubmit}
                          type="submit"
                          variant="contained"
                          sx={{ color: "white", background: "black" }}
                          startIcon={<Edit sx={{ fontSize: 1 }} />}
                        >
                         update
                        </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  </div></Box>
  );
};

export default Create;

// const defaultMeasureUnit= {
//   KG: 'KG',
//   GRAM: 'GRAM',
//   LITER: 'LITER',
//   ML:'ML',
//   PIECE: 'PIECE',
//   METER: 'METER',
//   getAllDefaultMeasureUnitasArray: function () {
//     return [
//       defaultMeasureUnit.KG,
//       defaultMeasureUnit.GRAM,
//       defaultMeasureUnit.LITER,
//       defaultMeasureUnit.PIECE,
//       defaultMeasureUnit.METER,
//       defaultMeasureUnit.ML,

//     ];

//   },
// }