import React, { useEffect, useRef, useState } from "react";
import "./createStyle.css";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Payment from "../../../services/payment.service";
import InVoice from "../../../services/invoice.service";
import Order from "../../../services/order.service";

import CONSTANT from "../../../constant/Option";
import { useParams } from "react-router-dom";

import Helper from "../../../utils/helper";
import OPTIONS from "../../../constant/Option";
import Commitment from "../../../services/commitment.service";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Create = () => {
  //HANDLING THE SELECT OPTION OF ROLE
  const [formData, setFormData] = React.useState({
    employeeName: "",
    customerName: "",
    typeOfPayment: "",
    commitedAmount: "",
    chequeNumber:"",
    payableDate:" ",
    orderId: "",
  });

  const [loading, setLoading] = React.useState(false);

  const Roles = ["GRAM", "KG", "LITER", "MILI"];

  //**************************** */

  //HANDLING THE IMAGE UPLOAD
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { id } = useParams();

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  const handleInputChange = (name, value) => {
    console.log("change input called", name, value);
    setFormData({ ...formData, [name]: value });
  };

 

  function CleanData() {
    const fields = Object.keys(formData);
    console.log("your fields", fields);
    let newObject = {};
    fields.map((key) => {
      newObject[key] = "";
    });
    setImagePreview(null);
    setFormData(newObject);
  }


  const handleSubmit = () => {
    try {



      const validationRules = [
        { fieldName: "commitedAmount", rules: { isRequired: true, isPositive: true } },
        { fieldName: "typeOfPayment", rules: { isRequired: true } },
        { fieldName: "payableDate", rules: { isRequired: true } },
        formData.typeOfPayment === OPTIONS.defaultPaymentType.CHECK
          ? { fieldName: "chequeNumber", rules: { isRequired: true } }
          : null, // This might be null


      ].filter(Boolean); // Filter out any null or undefined values


      if(!Helper.isFormValid2(formData, validationRules)){
        console.log("returning false")
        return;
      }

      const newformData = new FormData();
      Object.keys(formData).map((key) => {
        newformData.append(key, formData[key]);
      });
      console.log("Ready to send data",newformData)
      setLoading(true);
      Commitment.Create(newformData, formData._id);
      CleanData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  async function fetchData() {
    const data = await Order.getById(id);
    const newdata = data.data[0];
    console.log("*****your Data***", data.data[0]);
    setFormData({
      customerName:
        newdata.customerInfo.firstName + " " + newdata.customerInfo.lastName,
        orderId:id,
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="row w-100 m-0 p-0">
        <div className="col-12 m-0 p-0">
          <div className="row w-100 ">
            <div className="col-12">
              {" "}
              <Typography
                variant="h"
                fontSize={25}
                p={1}
                style={{ fontWeight: "bold" }}
              >
                Commitment {" "} No.
              </Typography>
              <Chip color={"error"} label={2} />
            </div>
            <div className="col-12">
              <Typography variant="caption" fontSize={15} p={1}>
               commitment Left {1} amount left 250
              </Typography>
            </div>
          </div>
        </div>

        <div className="col-12 my-3 ">
          <Stack marginLeft={2} direction="row" spacing={2}>
            <Link
              component="button"
              variant="body"
              onClick={() => {
                console.info("I'm a button.");
              }}
              sx={{ color: "black", textDecoration: "none" }}
            >
              DashBoard
            </Link>
            <Link
              component="button"
              variant="body"
              sx={{ color: "black", textDecoration: "none" }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              Commitment
            </Link>

            <Link
              component="button"
              variant="body"
              sx={{ color: "black", textDecoration: "none" }}
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              Create
            </Link>
          </Stack>
        </div>

        <div
          className="col-md-4 col-12 d-flex justify-content-center"
          id="uploadSection"
        >
          <div className="row " style={{ width: "98%" }}>
            <div
              className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
              style={{
                maxHeight: "300px",
                minHeight: "300px",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div
                className="rounded-circle border d-flex justify-content-center align-items-center"
                style={{
                  minWidth: "150px",
                  minHeight: "150px",
                  background: "darkgrey",
                }}
                onClick={handleDivClick}
              >
                {imagePreview || formData.imageUrl ? (
                  <img
                    src={imagePreview ? imagePreview : formData.imageUrl}
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      maxWidth: "130px",
                      minHeight: "130px",
                      maxHeight: "130px",
                      background: "white",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                    style={{
                      minWidth: "130px",
                      minHeight: "130px",
                      background: "white",
                    }}
                  >
                    <CameraAltIcon></CameraAltIcon>
                    <span>Upload Photo</span>
                  </div>
                )}
              </div>
              <span className="my-3"> PNG jPEG GIF</span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>

        <div
          className="col-md-7 col-11  my-2 py-1 mx-2 mx-auto d-flex justify-content-center rounded "
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div
            className="row  d-flex justify-content-spacebetween my-sm-5 my-2 "
            style={{ width: "95%" }}
          >
            <div className="col-12 col-md-6 my-md-3 my-1 p">
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="Order ID"
                value={formData.orderId}
                name="orderId"
                // onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
                variant="outlined"
              />
            </div>
            <div className="col-12 col-md-6  my-md-3 my-1">
              <TextField
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Customer Name"
                variant="outlined"
                value={formData.customerName}
                name="customerName"
                // onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
              />
            </div>
            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <TextField
                step="1"
                oninput="validity.valid||(value='');"
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Committed Amount"
                variant="outlined"
                type="number" // Set type to number for numerical input
                inputProps={{ min: 1, step: 1 }} // Enforce positive integers
                helperText="Enter positive Amount Only"
                value={formData.commitedAmount}
                name="commitedAmount"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>


            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Payment Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.typeOfPayment}
                  label="Payment Type"
                  name="typeOfPayment"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                >
                  {CONSTANT.defaultPaymentType
                    .getAllDdefaultPaymentTypeasArray()
                    .map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </div>
            


          
          {
            formData.typeOfPayment==OPTIONS.defaultPaymentType.CHECK?(  <div className="col-12 col-md-6  my-md-3 my-1 ">
              <TextField
                step="1"
                oninput="validity.valid||(value='');"
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="Cheque Number"
                variant="outlined"
                type="text" // Set type to number for numerical input
                inputProps={{ min: 1, step: 1 }} // Enforce positive integers
                value={formData.chequeNumber}
                name="chequeNumber"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>):null
          }


            <div className="col-12 col-md-6  my-md-3 my-1 ">
              <TextField
                step="1"
              
                oninput="validity.valid||(value='');"
                id="outlined-basic"
                sx={{ width: "100%" }}
                label="payable Date"
                variant="outlined"
                type="date" // Set type to number for numerical input            
                value={formData.payableDate || " "}
                name="payableDate"
                onChange={(e) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              />
            </div>

            



            <div className="col-12 text-center my-md-3 my-1">
              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                sx={{ color: "white", background: "black" }}
                startIcon={<AddIcon sx={{ fontSize: 1 }} />}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;

