import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";

import { AddBusiness, DomainAdd, Send } from "@mui/icons-material";

import Link from "@mui/material/Link";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import {
  addUser,
  removeUser,
  updateUser,
  addBulkUser,
} from "../../Component/redux/astroSlice";

import EnhancedTable from "./userTable";
import { useNavigate } from "react-router-dom";

import User from "../../services/user.service";
import OPTIONS from "../../constant/Option";
import { useDispatch, useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["ADMIN", "SUPER_ADMIN", "EMPLOYEE", "RETAILER", "WHOLE_SALER"];

const UserList = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [personName, setPersonName] = React.useState([]);
  const [search, setSearch] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  // const rows=useSelector((state)=>state.User);

  const dispatch = useDispatch();

  const [filter, SetFilter] = useState({
    search: "",
    role: [],
  });

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // filter.role(typeof value === "string" ? value.split(",") : value)
    SetFilter({
      ...filter,
      role: typeof value === "string" ? value.split(",") : value,
    });
  };

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const data = await User.getAllUser({ ...filter });
      console.log("*******master filter is called ***********", data);
      dispatch(addBulkUser(data.data));
      setRows(data.data);
      // setRowCount(data.totalCount)
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Cleanup function

  useEffect(() => {
    fetchData();
  }, [filter]);

  // useEffect(async()=>{
  //   console.log("*******when the component will mount******");

  // //  const data= User.getAllUser({})
  // //  console.log("*******User list mount to the state******");

  // },[])

  return (
   <Box sx={{height:"100%",overflowY:"auto"}}> <div>
   <div className="row w-100 m-0 p-0 border-left">
     <div className="col-12">
       <div className="row m-0 p-0 my-3 d-flex justify-content-between">
         <div className="col-6 col-sm-2" style={{ overflowY: "hidden" }}>
           <Typography
             variant="caption"
             fontSize={25}
             p={1}
             style={{ fontWeight: "bold" }}
           >
             List
           </Typography>
         </div>
         <div className="col-6 col-sm-2 ">
           <Button
             variant="contained"
             sx={{ background: "black", borderRadius: "5px" }}
             onClick={(e) => {
               navigate("/dashboard/user/create");
             }}
           >
             New User
           </Button>
         </div>
       </div>
     </div>

     <div className="col-12 my-3 ">
       <Stack marginLeft={2} direction="row" spacing={2}>
         <Link
           component="button"
           variant="body"
           onClick={() => {
             console.info("I'm a button.");
           }}
           sx={{ color: "black", textDecoration: "none" }}
         >
           DashBoard
         </Link>
         <Link
           component="button"
           variant="body"
           sx={{ color: "black", textDecoration: "none" }}
           onClick={() => {
             console.info("I'm a button.");
           }}
         >
           User
         </Link>

         <Link
           component="button"
           variant="body"
           sx={{ color: "black", textDecoration: "none" }}
           onClick={() => {
             console.info("I'm a button.");
           }}
         >
           List
         </Link>
       </Stack>
     </div>
     <div className="col-12 custom-scrollbar">
       <Box sx={{ width: "100%" }}>
         <Tabs
           value={value}
           onChange={handleChange}
           textColor="primary"
           indicatorColor="primary"
           variant="scrollable"
           scrollButtons="auto"
           aria-label="scrollable auto tabs example"
         >
           <Tab
             label="All"
             {...a11yProps(0)}
             sx={{ color: "black", fontWeight: "700" }}
           />
           <Tab
             label="Active"
             {...a11yProps(1)}
             sx={{ color: "black", fontWeight: "700" }}
           />
           <Tab
             label="Deleted"
             {...a11yProps(2)}
             sx={{ color: "black", fontWeight: "700" }}
           />
           <Tab
             label="Black"
             {...a11yProps(3)}
             sx={{ color: "black", fontWeight: "700" }}
           />
         </Tabs>
       </Box>
     </div>
     <div className="col-12 my-3">
       <div className="row w-100  m-0  p-0">
         <div className="col-12 p-0 col-md-3 d-flex justify-content-center  ">
           <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
             <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
             <Select
               labelId="demo-multiple-checkbox-label"
               id="demo-multiple-checkbox"
               multiple
               value={filter.role}
               onChange={handleChange2}
               input={<OutlinedInput label="Tag" />}
               renderValue={(selected) => selected.join(", ")}
               MenuProps={MenuProps}
             >
               {OPTIONS.usersRoles.getAllRolesOfUserAsArray().map((name) => (
                 <MenuItem key={name} value={name}>
                   <Checkbox checked={filter.role.indexOf(name) > -1} />
                   <ListItemText primary={name} />
                 </MenuItem>
               ))}
             </Select>
           </FormControl>
         </div>
         <div className="col-md-9 col-12 px-md-2  p-0 m-0 my-md-0 my-3  d-flex justify-content-start  justify-content-md-center">
           <OutlinedInput
             value={filter.search}
             onChange={(e) => {
               SetFilter({ ...filter, search: e.target.value });
             }}
             color="primary"
             placeholder="Search..."
             sx={{ width: "100%", height: "55px" }}
             startAdornment={
               <InputAdornment position="start">
                 <SearchIcon />
               </InputAdornment>
             }
           />
         </div>
       </div>
     </div>

     <div className="col-12  p-0 show-scrollbar ">
       <EnhancedTable
         data={{ filter, SetFilter, rowCount, fetchData, rows }}
       />
     </div>
   </div>
 </div></Box>
  );
};

export default UserList;
