
import apiRequest from "../Component/customHool/axiosRequest";



const Commitment = {
  async getAllByOrderId(id) {
   
    return apiRequest('GET',`/commitment/getAllByOrderId/${id}`,{},{})
  },

  async Create(payload) {
    
    return apiRequest('POST',`/commitment/create`,payload,{})
  },

  async getById(id) {
   
    return apiRequest('GET',`/payment/getById/${id}`,{},{})
  },

  async updateById(payload, id) {
   

    return apiRequest('PUT',`/payment/update/${id}`,payload,{})
  },

  async delete(id) {
   
    return apiRequest('DELETE',`/payment/delete/${id}`,{},{})
  },


  async acceptedByAdmin(id) {
  

    return apiRequest('PUT',`/payment/recievePayment/${id}`,{},{})
  },




};

export default Commitment;
