import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Component/redux/store";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SocketProvider } from "../src/useSocket.js/socket";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SocketProvider>
      <App />
      <ToastContainer   position="top-center" // Customize position (e.g., 'top-right', 'top-center', 'bottom-right', etc.)
        autoClose={2200} // Set auto close time in milliseconds
        hideProgressBar={false} // Show or hide the progress bar
        newestOnTop={false} // Newest toast appears on top
        closeOnClick // Close the toast when clicked
        rtl={false} // Right-to-left layout
        pauseOnFocusLoss // Pause when the window loses focus
        draggable // Allow dragging the toast
        pauseOnHover // Pause when hovering over the toast
        theme="light" // Set theme (e.g., 'light', 'dark', 'colored')
         
        />
    </SocketProvider>
  </Provider>
);

reportWebVitals();
