import React, { useEffect, useState } from "react";
import ItemCart from "./itemCart";
import { Box } from "@mui/material";
import Product from "../../../services/product.service"
const Items = () => {
const [product,setProduct]=useState([]);
const [filter,setFilter]=useState({})

async function fetchProducts() {
   const response=await Product.getAll(filter)
   setProduct(response.data);
} 


useEffect(()=>{
  fetchProducts();
},[]);

  return (
  <Box sx={{height:"100%",overflowY:"auto"}}>  <div className="row d-flex  m-0 ">
  {product
    .map((item) => {
    return  <ItemCart imageUrl={item.imageUrl} title={item.title} _id={item._id} unit={item.unit} measureUnit={item.measureUnit} price={item.price}/>;
    })}
</div></Box>
  );
};

export default Items;
