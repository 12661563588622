import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Padding, Rowing } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Send } from "@mui/icons-material";

import Link from "@mui/material/Link";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import CONSTANT from "../../../constant/Option";


import EnhancedTable from "./userTable";
import { useNavigate } from "react-router-dom";
import Payment from "../../../services/payment.service";

import { addBulkPayment } from "../../../Component/redux/astroSlice";
import { useSelector,useDispatch } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const UserList = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [personName, setPersonName] = React.useState([]);
  const [search, setSearch] = React.useState([]);

  const dispatch=useDispatch();

  const [filter,SetFilter]=useState({
   search:"",
   typeOfPayment:[]
  })

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // filter.role(typeof value === "string" ? value.split(",") : value)
    SetFilter({...filter,typeOfPayment:typeof value === "string" ? value.split(",") : value})
  };

  const navigate=useNavigate();


  useEffect(()=>{
    console.log("*******master filler is called ***********",filter)
    fetchData();
  },[filter])


  async function fetchData(){
 const data=await Payment.getAll(filter);
 console.log("data",data)
 dispatch(addBulkPayment(data.data));
 
  }


  useEffect(()=>{
    fetchData();
  },[])


  return (
  <Box sx={{height:"100%",overflowY:"auto"}}>  <div>
  <div className="row w-100 m-0 p-0 " >

    <div className="col-12">
      <div className="row m-0 p-0 my-3 d-flex justify-content-between">
        <div className="col-6 col-sm-2 p-0" style={{ overflowY: "hidden" } }>
          <Typography
            variant="caption"
            fontSize={25}
            p={1}
            style={{ fontWeight: "bold" }}
          >
           Payment
          </Typography>
        </div>
        <div className="col-6 col-sm-2 ">
         
        </div>
      </div>
    </div>

    <div className="col-12 my-3 ">
      <Stack marginLeft={2} direction="row" spacing={2}>
        <Link
          component="button"
          variant="body"
          onClick={() => {
            console.info("I'm a button.");
          }}
          sx={{ color: "black",textDecoration:"none"}}
        >
          DashBoard
        </Link>
        <Link
          component="button"
          variant="body"
          sx={{ color: "black",textDecoration:"none"}}
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Payment
        </Link>

        <Link
          component="button"
          variant="body"
          sx={{ color: "black",textDecoration:"none"}}
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          List
        </Link>
      </Stack>
    </div>
    <div className="col-12 custom-scrollbar">
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
         
        >
          <Tab label="All" {...a11yProps(0)} sx={{ color: "black",fontWeight:"700" }} />
          <Tab label="Active" {...a11yProps(1)} sx={{ color: "black",fontWeight:"700"}} />
          <Tab label="Deleted" {...a11yProps(2)} sx={{ color: "black",fontWeight:"700" }} />
          <Tab label="Black" {...a11yProps(3)} sx={{ color: "black",fontWeight:"700" }} />

        </Tabs>
      </Box>
    </div>
    <div className="col-12 my-3">
      <div className="row w-100  m-0  p-0">
        <div className="col-12 p-0 col-md-3 d-flex justify-content-center border-danger "  > 
          <FormControl sx={{ minWidth:"100%", maxWidth:"100%"} } >
            <InputLabel id="demo-multiple-checkbox-label">Payment Type</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filter.typeOfPayment}
              onChange={handleChange2}
              input={<OutlinedInput  label="Status" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}               
            >
              {CONSTANT.defaultPaymentType.getAllDdefaultPaymentTypeasArray().map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filter.typeOfPayment.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          className="col-md-9 col-12 px-md-2  p-0 m-0 my-md-0 my-3  d-flex justify-content-start  justify-content-md-center"
         
        >
          <OutlinedInput
            value={filter.search}
            onChange={e=>{SetFilter({...filter,search:e.target.value})}}

            color="primary"
            placeholder="Search..."
            sx={{ width: "100%", height: "55px", }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
      </div>
    </div>

    <div className="col-12  p-0 show-scrollbar " >
       <EnhancedTable />
    </div>
  </div>
</div></Box>
  );
};

export default UserList;