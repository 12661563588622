import React, { useEffect, useRef, useState } from "react";
import "./createStyle.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextField from "@mui/material/TextField";
import axios from "axios";

import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';


import CONSTANT from "../../../constant/Option";
import Product from "../../../services/product.service";
import { toast } from "react-toastify";
import Helper from "../../../utils/helper";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};





const Create = () => {
  //HANDLING THE SELECT OPTION OF ROLE
  const [formData, setFormData] = React.useState({});
  const [loading,setLoading]=React.useState(false);
  
  const Roles = ["GRAM", "KG", "LITER", "MILI"];

 

  //**************************** */

  //HANDLING THE IMAGE UPLOAD
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);


  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({...formData,image:file})
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************* */

  const handleInputChange=(name,value)=>{
    // let name=e.target.name;
    // let value=e.target.value;
    console.log("change input called",name,value)
    setFormData({...formData,[name]:value})
  }

  useEffect(()=>{
    console.log("your form data is changed",formData)
  },[formData])



  function CleanData(){
    const fields=Object.keys(formData);
    console.log("your fields",fields);
    let newObject={};
    fields.map((key)=>{
       newObject[key]='';
    })
    newObject['measureUnit'] = '';
    setImagePreview(null);
    setFormData(newObject);
  }


  const validateArray = [
   
    { fieldName: "salePrice", rules: { isRequired: true} },
    { fieldName: "price", rules: { isRequired: true } },
    { fieldName: "salePrice", rules: { isRequired: true ,isPositive:true} },
    { fieldName: "price", rules: { isRequired: true,isPositive:true } },
    { fieldName: "stock", rules: { isRequired: true,isPositive:true } },
    { fieldName: "unit", rules: { isRequired: true,isPositive:true } },
    { fieldName: "measureUnit", rules: { isRequired: true } },
    { fieldName: "description", rules: { isRequired: true } },
    { fieldName: "subDescription", rules: { isRequired: true } },
    { fieldName: "title", rules: { isRequired: true } },
  ];

  const handleSubmit=async()=>{
   try {
    if(!Helper.isFormValid2(formData,validateArray)){
      return;
    }
    const newformData=new FormData();
    Object.keys(formData).map((key)=>{
     newformData.append(key,formData[key])
    })

    setLoading(true)
      const data=await Product.Create(newformData);
          CleanData();
      setLoading(false)  

   } catch (error) {
    setLoading(false);
   }
  }

  return (
   <Box sx={{height:"100%",overflowY:"auto"}}> <div>
   <div className="row w-100 m-0 p-0">
     <div className="col-12 m-0 p-0">
     <div className="row m-0 my-3 d-flex justify-content-between">
         <div className="col-6 col-sm-8 p-0 " style={{ overflowY: "hidden" }}>
           <Typography
             variant="caption"
             fontSize={25}
             p={1}
             style={{ fontWeight: "bold" }}
           >
           Product
           </Typography>
         </div>
         <div className="col-6 col-sm-4  d-flex justify-content-end">
           <Button
             variant="contained"
             sx={{ background: "black", borderRadius: "5px",width:"80%",maxWidth:"120px"}}
           >
            Back
           </Button>
         </div>
       </div>
     </div>

     <div className="col-12 my-3 ">
       <Stack marginLeft={2} direction="row" spacing={2}>
         <Link
           component="button"
           variant="body"
           onClick={() => {
             console.info("I'm a button.");
           }}
           sx={{ color: "black", textDecoration: "none" }}
         >
           DashBoard
         </Link>
         <Link
           component="button"
           variant="body"
           sx={{ color: "black", textDecoration: "none" }}
           onClick={() => {
             console.info("I'm a button.");
           }}
         >
           Product
         </Link>

         <Link
           component="button"
           variant="body"
           sx={{ color: "black", textDecoration: "none" }}
           onClick={() => {
             console.info("I'm a button.");
           }}
         >
          Create
         </Link>
       </Stack>
     </div>

     <div
       className="col-md-4 col-12 d-flex justify-content-center"
       id="uploadSection"
     >
       <div className="row " style={{ width: "98%" }}>
         <div
           className="col-11 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
           style={{
             maxHeight: "300px",
             minHeight: "300px",
             boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
           }}
         >
           <div
             className="rounded-circle border d-flex justify-content-center align-items-center"
             style={{ minWidth: "150px", minHeight: "150px",background:"darkgrey" }}
             onClick={handleDivClick}
           >
             {imagePreview ? (
               <img
                 src={imagePreview}
                 className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                 style={{
                   minWidth: "130px",
                   maxWidth: "130px",
                   minHeight: "130px",
                   maxHeight: "130px",
                   background:'white'
                 }}
               />
             ) : (
               <div
                 className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                 style={{
                   minWidth: "130px",
                   minHeight: "130px",
                   background: "white",
                 }}
               >
                 <CameraAltIcon></CameraAltIcon>
                 <span>Upload Photo</span>
               </div>
             )}
           </div>
           <span className="my-3"> PNG jPEG GIF</span>
         </div>
       </div>
       <input
         type="file"
         ref={fileInputRef}
         style={{ display: "none" }}
         onChange={handleFileChange}
       />
     </div>

     <div
       className="col-md-7 col-11  my-2 py-1 mx-2 mx-auto d-flex justify-content-center rounded "
       style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
     >
       <div
         className="row  d-flex justify-content-center my-sm-5 my-2 "
         style={{ width: "95%" }}
       >
         <div className="col-12 col-md-6 my-md-3 my-1 p">
           <TextField
             sx={{ width: "100%" }}
             id="outlined-basic"
             label="Title"
             value={formData.title}
             name='title'
             inputProps={{ maxLength: 30 }} // Limits input to 10 characters
             helperText={`${formData.title?.length?formData.title?.length:0}/30`}

             onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
             variant="outlined"
           />
         </div>
         <div className="col-12 col-md-6  my-md-3 my-1">
           <TextField
             id="outlined-basic"
            
             sx={{ width: "100%" }}
             label="Sub Description"
             variant="outlined"
             value={formData.subDescription}
             name='subDescription'
             onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
           />
         </div>
         <div className="col-12 col-md-6  my-md-3 my-1">
           <TextField
             id="outlined-basic"
             sx={{ width: "100%" }}
             label="Price"
             variant="outlined"
             value={formData.price}
             name='price'
             type="number"
             inputProps={{ min: 0, step: 'any' }}
             onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
           />
         </div>

       

         <div className="col-12 col-md-6 my-md-3 my-1">
           <TextField
             id="outlined-basic"
             sx={{ width: "100%" }}
             label="Sale Price"
             variant="outlined"
             value={formData.salePrice}
             name='salePrice'
             type="number"
             inputProps={{ min: 0, step: 'any' }}
             onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
           />
         </div>
         <div className="col-12 col-md-6  my-md-3 my-1 ">
           <TextField
             id="outlined-basic"
             sx={{ width: "100%" }}
             label="Unit"
             variant="outlined"  
              value={formData.unit}
             name='unit'
             type="number"
             onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
             inputProps={{ min:0, step: 'any' }}
           />
         </div>
         <div className="col-12 col-md-6  my-md-3 my-1 ">
           <FormControl  sx={{ width: "100%" }}>
     <InputLabel id="demo-simple-select-label">Measure Unit</InputLabel>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={ formData.measureUnit || ''} 
       label="Measure Unit"
       name="measureUnit"
       onChange={e=>handleInputChange(e.target.name,e.target.value)}
     >
         {
            CONSTANT.defaultMeasureUnit.getAllDefaultMeasureUnitasArray().map((item)=>{
                 return  <MenuItem value={item}>{item}</MenuItem>
             })
         }
      
     </Select>
   </FormControl>
         </div>
         <div className="col-12 col-md-6 my-md-3 my-1 ">
           <TextField
             type="text"
             id="outlined-basic"
             sx={{ width: "100%" }}
             label="Description"
             variant="outlined" 
             value={formData.description}
             name="description"
             onChange={e=>handleInputChange(e.target.name,e.target.value)}
           />
         </div>

         <div className="col-12 col-md-6  my-md-3 my-1">
           <TextField
             id="outlined-basic"
             sx={{ width: "100%" }}
             label="Stock"
             variant="outlined"
             value={formData.stock}
             name='stock'
             type="number"
             onChange={e=>{handleInputChange(e.target.name,e.target.value)}}
           />
         </div>
         {/* <div className="col-12 col-md-6 my-md-3 my-1 ">
           <TextField
             type="text"
             id="outlined-basic"
             sx={{ width: "100%" }}
             label="Confirm Pass"
             variant="outlined"
           />
         </div> */}
         <div className="col-12 text-center my-md-3 my-1">
           {/* <Button variant="contained" sx={{color:"white",background:"black"}} onClick={handleSubmit}>Submit</Button> */}
           <LoadingButton
                         loading={loading}
                         onClick={handleSubmit}
                         type="submit"
                         variant="contained"
                         sx={{ color: "white", background: "black" }}
                         startIcon={<AddIcon sx={{ fontSize: 1 }} />}
                       >
                        Submit
                       </LoadingButton>
         </div>
       </div>
     </div>
   </div>
 </div></Box>
  );
};

export default Create;

// const defaultMeasureUnit= {
//   KG: 'KG',
//   GRAM: 'GRAM',
//   LITER: 'LITER',
//   ML:'ML',
//   PIECE: 'PIECE',
//   METER: 'METER',
//   getAllDefaultMeasureUnitasArray: function () {
//     return [
//       defaultMeasureUnit.KG,
//       defaultMeasureUnit.GRAM,
//       defaultMeasureUnit.LITER,
//       defaultMeasureUnit.PIECE,
//       defaultMeasureUnit.METER,
//       defaultMeasureUnit.ML,

//     ];

//   },
// }