import apiRequest from "../Component/customHool/axiosRequest";

const NotificationSerivce = {
  async getNotification() {
    return apiRequest('GET', '/notification/getMyNotification', {}, {});
  },

//   async addtoCart(payload) {
//     return apiRequest('POST', '/cart/addToCart', payload, {});
//   },

//   async updateDiscount(payload, id) {
//     return apiRequest('PUT', `/cart/updateDiscount`, payload, {});
//   },



//   async getById(payload) {
//     return apiRequest('GET', `/invoice/getById/${payload}`, {}, {});
//   },

//   async updateById(payload, id) {
//     return apiRequest('PUT', `/invoice/update/${id}`, payload, {});
//   },

 

//   async delete(id) {
//     return apiRequest('DELETE', `/invoice/delete/${id}`, {}, {});
//   },
};

export default NotificationSerivce;
