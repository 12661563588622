import * as React from "react";
import Box from "@mui/material/Box";
import { Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AlignVerticalCenterTwoToneIcon from "@mui/icons-material/AlignVerticalCenterTwoTone";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CheckroomTwoToneIcon from "@mui/icons-material/CheckroomTwoTone";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";

import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import logo from "../../assets/images/logoDealler.png";
import OPTIONS from "../../constant/Option";
import { useSelector } from "react-redux";
import { isNull } from "util";

const DrawerBox = (props) => {
  console.log("your props", props);

  const [open, setOpen] = React.useState(false);
  const [productOpen, setProductOpen] = React.useState(false);
  const [orderOpen, setOrderOpen] = React.useState(false);
  const [invoiceOpen, setInvoiceOpen] = React.useState(false);
  const [paymentOpen, setPaymentOpen] = React.useState(false);
  const [depostOpen, setDepositOpen] = React.useState(false);
  const [visitOpen, setVisitOpen] = React.useState(false);
  const [paymentCommitment, setPaymentCommitment] = React.useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  const handleClickRoute = (e) => {
    navigate("/user");
  };
  return (
    <div>
      <Box sx={{ width: "100%", minWidth: "180px" }} role="presentation">
        <img
          className="d-lg-none"
          src={logo}
          alt="logo"
          style={{ height: "68px", marginRight: "15px" }}
        />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {OPTIONS.usersRoles
            .onlyEmployeeAccess()
            .includes(currentUser.role) ? (
            <>
              <ListItemButton
                color="primary"
                sx={{ color: "green", fontWeight: 600, fontFamily: "Raleway" }}
                onClick={handleClick}
              >
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="User"
                  sx={{ fontFamily: "Raleway" }}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      navigate("/dashboard/user");
                    }}
                  >
                    <ListItemIcon>
                      <AlignVerticalCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="List" />
                  </ListItemButton>
                </List>

                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate("/dashboard/user/create");
                  }}
                >
                  <ListItemIcon>
                    <AlignVerticalCenterTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Create" />
                </ListItemButton>
              </Collapse>
            </>
          ) : null}

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setProductOpen(!productOpen)}
          >
            <ListItemIcon>
              <CheckroomTwoToneIcon />
            </ListItemIcon>
            <ListItemText
              primary="Product"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {productOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={productOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {OPTIONS.usersRoles
                .onlySubAdminAccess()
                .includes(currentUser.role) ? (
                <>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <AlignVerticalCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="List"
                      onClick={(e) => {
                        navigate("/dashboard/product");
                      }}
                    />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ pl: 4, boxShadow: "" }}
                    onClick={(e) => {
                      navigate("/dashboard/product/create");
                    }}
                  >
                    <ListItemIcon>
                      <AlignVerticalCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create" />
                  </ListItemButton>
                </>
              ) : null}

              <ListItemButton
                sx={{ pl: 4, boxShadow: "" }}
                onClick={(e) => {
                  navigate("/dashboard/product/items");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Items" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setOrderOpen(!orderOpen)}
          >
            <ListItemIcon>
              <ShoppingCartCheckoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Order"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {orderOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={orderOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/order");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="List" />
              </ListItemButton>

              {/* <ListItemButton sx={{ pl: 4 }}  >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Detail" />
              </ListItemButton> */}
            </List>
          </Collapse>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setPaymentOpen(!paymentOpen)}
          >
            <ListItemIcon>
              <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  color="currentColor"
                >
                  <path d="M20.016 2C18.903 2 18 4.686 18 8h2.016c.972 0 1.457 0 1.758-.335c.3-.336.248-.778.144-1.661C21.64 3.67 20.894 2 20.016 2"></path>
                  <path d="M18 8.054v10.592c0 1.511 0 2.267-.462 2.565c-.755.486-1.922-.534-2.509-.904c-.485-.306-.727-.458-.996-.467c-.291-.01-.538.137-1.062.467l-1.911 1.205c-.516.325-.773.488-1.06.488s-.545-.163-1.06-.488l-1.91-1.205c-.486-.306-.728-.458-.997-.467c-.291-.01-.538.137-1.062.467c-.587.37-1.754 1.39-2.51.904C2 20.913 2 20.158 2 18.646V8.054c0-2.854 0-4.28.879-5.167C3.757 2 5.172 2 8 2h12"></path>
                  <path d="M10 8c-1.105 0-2 .672-2 1.5s.895 1.5 2 1.5s2 .672 2 1.5s-.895 1.5-2 1.5m0-6c.87 0 1.612.417 1.886 1M10 8V7m0 7c-.87 0-1.612-.417-1.886-1M10 14v1"></path>
                </g>
              </svg>
            </ListItemIcon>
            <ListItemText
              primary="Payment"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {paymentOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={paymentOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/payment");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="List" />
              </ListItemButton>

              {/* 
              <ListItemButton sx={{ pl: 4 }}  >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon  />
                </ListItemIcon>
                <ListItemText primary="Create" />
              </ListItemButton> */}
            </List>

            {/* <ListItemButton sx={{ pl: 4, boxShadow: "" }} >
              <ListItemIcon>
                <AlignVerticalCenterTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </ListItemButton> */}
          </Collapse>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setDepositOpen(!depostOpen)}
          >
            <ListItemIcon>
              <PeopleAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="InVoice"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {depostOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={depostOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/invoice");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="List" />
              </ListItemButton>

              {/* <ListItemButton sx={{ pl: 4 }} >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Detail" />
              </ListItemButton> */}
            </List>
          </Collapse>

          {OPTIONS.usersRoles
            .onlyEmployeeAccess()
            .includes(currentUser.role) ? (
            <>
              <ListItemButton
                color="primary"
                sx={{ color: "green" }}
                onClick={(e) => setVisitOpen(!visitOpen)}
              >
                <ListItemIcon>
                  <AddLocationAltIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Visit"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
                {visitOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={visitOpen} timeout="auto" unmountOnExit>
                <ListItemButton
                  sx={{ pl: 4, boxShadow: "" }}
                  onClick={(e) => {
                    navigate("/dashboard/visit");
                  }}
                >
                  <ListItemIcon>
                    <AlignVerticalCenterTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="List" />
                </ListItemButton>

                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={(e) => {
                      navigate("/dashboard/visit/create");
                    }}
                  >
                    <ListItemIcon>
                      <AlignVerticalCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create" />
                  </ListItemButton>

                  {OPTIONS.usersRoles
                    .onlyAdminAccess()
                    .includes(currentUser.role) ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          navigate("/dashboard/visit/view");
                        }}
                      >
                        <ListItemIcon>
                          <AlignVerticalCenterTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Visit Location" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          navigate("/dashboard/visit/liveLocation", {
                            replace: true,
                          });
                        }}
                      >
                        <ListItemIcon>
                          <AlignVerticalCenterTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Live Location" />
                      </ListItemButton>
                    </>
                  ) : null}
                </List>
              </Collapse>
            </>
          ) : null}
        </List>
      </Box>
    </div>
  );
};

export default DrawerBox;
