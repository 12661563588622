import * as React from "react";
import styles from "./notificationDrawer.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Switch, FormControlLabel } from "@mui/material";

import User from "../../services/user.service";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../redux/astroSlice";
import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import cartItem from "../../services/cartItem.service";

import { Grid, Avatar } from "@mui/material";
import FileIcon from "@mui/icons-material/InsertDriveFile"; // Example file icon

import CloseIcon from "@mui/icons-material/Close";
import { SetMealOutlined } from "@mui/icons-material";
// import CartService from "../../services/notification.service";
import NotificationSerivce from "../../services/notificationSerivce";
import OPTIONS from "../../constant/Option";
import Order from "../../services/order.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function CartDrawer() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const open1 = useSelector((state) => state.Notification);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to store selected user ID
  //   const NotificationAction = useSelector((state) => state.Cart);
  const NotificationAction = useSelector((state) => state.Notification);

  const [notification, setNotification] = useState([]);
  const currentUser = useSelector((state) => state.currentUser);
  const [isDiscount, setIsDiscount] = useState(false);

  console.log("OPENING THE NOTIFICATION", NotificationAction);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Replace this URL with your API endpoint to fetch users
      const response = await User.getAllUser();
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("****your selected id**", selectedUserId);
  }, [selectedUserId]);

  // Fetch users when the autocomplete is opened
  const handleOpen = () => {
    setOpen(true);
    fetchUsers(); // Fetch users only if options list is empty
  };

  const FetchCart = async () => {
    const response = await NotificationSerivce.getNotification();
    console.log("Fetch The Cart", response.data);
    setNotification(response.data);
  };

  useEffect(() => {
    console.log("fetch change gthe Set Cart", notification);
  });

  useEffect(() => {
    if (NotificationAction) {
      FetchCart();
    }
  }, [NotificationAction]);

  async function CheckOut() {
    try {
      if (
        OPTIONS.usersRoles.onlyCustomerLevelAccess().includes(currentUser.role)
      ) {
        setSelectedUserId((e) => currentUser._id);
      }
      console.log("***your User Id****", selectedUserId);
      if (selectedUserId) {
        await Order.Create({ customerId: selectedUserId });
      } else {
        toast.error("Select The Customer First");
      }
    } catch (error) {}
  }

  const DrawerList = (
    <Box sx={{ width: "100vw", maxWidth: "450px" }} role="presentation">
      <div className={`container p-1`}>
        <div className="row my-2 ms-3">
          <div className="col-10 p-0">
            <h1>Notifcation Cart</h1>
          </div>
          <div className="col-2">
            <CloseIcon
              onClick={(e) => {
                dispatch(closeNotification());
              }}
            />
          </div>
        </div>

        <Box
          sx={{
            width: "100%",
            height: "90vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {notification.length > 0 ? (
            notification.map(
              ({ title, type, message, _id, metadata, createdAt }) => (
                <NotificationCard
                  key={_id}
                  title={title}
                  type={type}
                  message={message}
                  metadata={metadata}
                  createdAt={createdAt}
                  close={ e=>{dispatch(closeNotification())}}
                />
              )
            )
          ) : (
            <Typography>No notifications available</Typography>
          )}
        </Box>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open1}
        anchor="right"
        onClose={(e) => dispatch(closeNotification())}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}

const NotificationCard = ({
  type,
  metadata,
  title,
  message,
  userId,
  _id,
  createdAt,
  close
}) => {
  const navigate = useNavigate(); // Initialize navigation\
  const [date, setDate] = useState("");

  // Define type-based mappings for background colors and routes
  const typeConfig = {
    orderPlaced: {
      color: "green",
      route: `/dashboard/order/detail/${metadata?.id}`,
    },
    paymentReceived: {
      color: "blue",
      route: `/dashboard/payment`,
    },
    paymentPending: { color: "yellow",  route: `/payments/${metadata?.id}`, },
    paymentReminder: {
      color: "orange",
      route: `/reminders/${metadata?.id}`,
    },
    accessProvided: {
      color: "green",
      route: `/dashboard/order/detail/${metadata?.id}`,
    },
  };

  // Set default color and route if the type doesn't match
  const { color, route } = typeConfig[type] || { color: "gray", route: "/" };

  // Handle View Button Click
  const handleViewClick = () => {
close()
    navigate(route); // Redirect to the corresponding page
    
  };

  useEffect(() => {
    const formattedDate = new Date(createdAt).toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // Set to India Standard Time (IST)
    });

    setDate(formattedDate);
  });

  return (
    <Card
      sx={{
        backgroundColor: color, // Dynamically set background color
        color: "white",
        borderRadius: 2,
        p: 0,
        my: 0.7,
        width: "98%",
        maxWidth: "98%",
        mx: "auto", // Centers horizontally
        boxShadow: 3,
      }}
    >
      <CardContent sx={{ m: 0, p: 0.5 }}>
        <Grid container alignItems="center" sx={{ m: 0, p: 0 }} spacing={0.5}>
          {/* Notification Text Section */}
          <Grid
            item
            xs
            sx={{
              m: 0,
              pl: 4,
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="body2">{message}</Typography>
          </Grid>

          {/* Time Section */}
          <Grid item sx={{ m: 0, p: 0, paddingRight: 0.5 }}>
            <Typography variant="body2" sx={{ opacity: 1 }}>
              {date.split(",")[1]}
            </Typography>
            <Button
              variant="contained"
              size="small"
              color="primary"
              sx={{ my: 1, textTransform: "none", boxShadow: "none" }}
              onClick={handleViewClick} // Attach click handler
            >
              View
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
